import React, {useState} from 'react';
import {IconTooltip} from 'wix-ui-tpa';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';

export enum GiftCardTooltipDataHooks {
  root = 'GiftCardTooltip.root',
}

interface EnterACodeButtonTooltipProps {
  dataHook: string;
  content: string;
}

export const EnterACodeButtonTooltip: React.FC<EnterACodeButtonTooltipProps> = ({dataHook, content}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  /* istanbul ignore next */
  const handleShowTooltip = () => {
    setShowTooltip(true);
  };
  /* istanbul ignore next */
  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <IconTooltip
      data-hook={dataHook}
      showTooltip={showTooltip}
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
      message={content}
      icon={<ErrorIcon />}
    />
  );
};
