/* eslint-disable */
/* tslint:disable */
import React from 'react';

export function LocaleKeys<R extends string>(t: (...args: unknown[]) => R) {
  return {
    checkout: {
      header_default_title: () => t('checkout.header_default_title'), /* SECURE CHECKOUT */
      header: {
        store_title_suffix: () => t('checkout.header.store_title_suffix'), /* CHECKOUT */
      },
      place_order: {
        place_order_button: () => t('checkout.place_order.place_order_button'), /* Place Order */
        $value: () => t('checkout.place_order'), /* Review & place order */
        description: () => t('checkout.place_order.description'), /* Review your details above and continue when you're ready. */
      },
      place_order_fast_flow: {
        delivery_method_title: () => t('checkout.place_order_fast_flow.delivery_method_title'), /* Delivery method */
        title: () => t('checkout.place_order_fast_flow.title'), /* Review and place your order */
        shipping_details_title: () => t('checkout.place_order_fast_flow.shipping_details_title'), /* Delivery details */
        billing_details_title: () => t('checkout.place_order_fast_flow.billing_details_title'), /* Billing Details */
        zip_code_title: () => t('checkout.place_order_fast_flow.zip_code_title'), /* Enter a zip / postal code */
        zip_code: {
          apply: () => t('checkout.place_order_fast_flow.zip_code.apply'), /* Apply */
        },
        region_select_title: (data: Record<'subdivision', unknown>) => t('checkout.place_order_fast_flow.region_select_title', data), /* Choose a {{subdivision}} */
        delivery_details_title: () => t('checkout.place_order_fast_flow.delivery_details_title'), /* Delivery details */
      },
      page: {
        generalPaymentError: {
          title: () => t('checkout.page.generalPaymentError.title'), /* We couldn't process your payment */
          subtitle: () => t('checkout.page.generalPaymentError.subtitle'), /* Please try again or contact the site owner. */
          cta: () => t('checkout.page.generalPaymentError.cta'), /* Got It */
        },
        technicalCalculationError: {
          title: () => t('checkout.page.technicalCalculationError.title'), /* There was a technical issue */
          subtitle: () => t('checkout.page.technicalCalculationError.subtitle'), /* We're working to fix it. Please try again later. */
          cta: () => t('checkout.page.technicalCalculationError.cta'), /* Got It */
        },
        siteLogo_altTextDefault: () => t('checkout.page.siteLogo_altTextDefault'), /* Store logo */
      },
      orderFailedModal: {
        invalidCardError: {
          title: () => t('checkout.orderFailedModal.invalidCardError.title'), /* Gift Card Code Isn’t Valid */
          content: () => t('checkout.orderFailedModal.invalidCardError.content'), /* Please check the code and try again. */
        },
        expiredCardError: {
          title: () => t('checkout.orderFailedModal.expiredCardError.title'), /* Your Gift Card Has Expired */
          content: () => t('checkout.orderFailedModal.expiredCardError.content'), /* Select a different payment method to complete this purchase. */
        },
        balanceChangedError: {
          title: () => t('checkout.orderFailedModal.balanceChangedError.title'), /* Your Gift Card Balance Has Changed */
          content: () => t('checkout.orderFailedModal.balanceChangedError.content'), /* Review your payment info before completing this purchase. */
        },
        insufficientFundsError: {
          title: () => t('checkout.orderFailedModal.insufficientFundsError.title'), /* Your Gift Card Balance is $0.00 */
          content: () => t('checkout.orderFailedModal.insufficientFundsError.content'), /* Select a different payment method to complete this purchase. */
        },
        genericFallbackError: {
          title: () => t('checkout.orderFailedModal.genericFallbackError.title'), /* Looks like there's a connection problem */
          content: () => t('checkout.orderFailedModal.genericFallbackError.content'), /* Please wait a few minutes and try again. */
        },
        returnToCheckout: {
          button: () => t('checkout.orderFailedModal.returnToCheckout.button'), /* Return to Checkout */
        },
      },
      paymentDetails: {
        enterGiftCard: {
          error: {
            replaceCurrency: {
              title: () => t('checkout.paymentDetails.enterGiftCard.error.replaceCurrency.title'), /* Change the currency to complete your order */
              content: () => t('checkout.paymentDetails.enterGiftCard.error.replaceCurrency.content'), /* The currency of this gift card doesn't match your cart. Change your cart's currency and try again. */
            },
            cardExpired: () => t('checkout.paymentDetails.enterGiftCard.error.cardExpired'), /* Your gift card has expired. */
            invalidCardNumber: () => t('checkout.paymentDetails.enterGiftCard.error.invalidCardNumber'), /* Gift card code isn’t valid. Please check the code and try again. */
            noBalance: () => t('checkout.paymentDetails.enterGiftCard.error.noBalance'), /* You’ve run out of money on this gift card. */
            genericFallback: {
              $value: () => t('checkout.paymentDetails.enterGiftCard.error.genericFallback'), /* It looks like there's a connection problem. Please try again. */
              title: () => t('checkout.paymentDetails.enterGiftCard.error.genericFallback.title'), /* This gift card code isn’t valid */
              content: () => t('checkout.paymentDetails.enterGiftCard.error.genericFallback.content'), /* To complete your purchase, go back and remove the gift card code. */
            },
            cardDisabled: () => t('checkout.paymentDetails.enterGiftCard.error.cardDisabled'), /* Your gift card has been disabled. */
            wrongCurrency: () => t('checkout.paymentDetails.enterGiftCard.error.wrongCurrency'), /* Gift card currency does not match the currency of our site. */
            serviceDown: {
              $value: () => t('checkout.paymentDetails.enterGiftCard.error.serviceDown'), /* Gift card service is temporarily down. Please try again later. */
              subtitle: () => t('checkout.paymentDetails.enterGiftCard.error.serviceDown.subtitle'), /* We’re working to fix it. Please try again later. */
              cta: () => t('checkout.paymentDetails.enterGiftCard.error.serviceDown.cta'), /* Return to Checkout */
              title: () => t('checkout.paymentDetails.enterGiftCard.error.serviceDown.title'), /* Gift card service is temporarily down */
              inline: () => t('checkout.paymentDetails.enterGiftCard.error.serviceDown.inline'), /* Sorry, we can't accept gift cards right now because of a technical issue. Please continue without a gift card or try again later. */
            },
          },
          placeholder: () => t('checkout.paymentDetails.enterGiftCard.placeholder'), /* Enter your code */
          CTA: () => t('checkout.paymentDetails.enterGiftCard.CTA'), /* Apply */
          remove: () => t('checkout.paymentDetails.enterGiftCard.remove'), /* Remove */
          clearButton: {
            ariaLabel: () => t('checkout.paymentDetails.enterGiftCard.clearButton.ariaLabel'), /* Clear gift card code */
          },
        },
        redeemGiftCard: {
          checkbox: {
            label: () => t('checkout.paymentDetails.redeemGiftCard.checkbox.label'), /* Redeem a gift card */
          },
          cantPurchaseGC: {
            tooltip: () => t('checkout.paymentDetails.redeemGiftCard.cantPurchaseGC.tooltip'), /* Gift cards can't be used to purchase other gift cards. */
          },
        },
        enterPromoCode: {
          placeholder: () => t('checkout.paymentDetails.enterPromoCode.placeholder'), /* e.g., SAVE50 */
          clearButton: {
            ariaLabel: () => t('checkout.paymentDetails.enterPromoCode.clearButton.ariaLabel'), /* Clear promo code */
          },
        },
      },
      minimumOrderModal: {
        withoutPickup: {
          title: (data: Record<'minAmount', unknown>) => t('checkout.minimumOrderModal.withoutPickup.title', data), /* We only deliver orders over {{minAmount}} */
          body: (data: Record<'additionalAmount', unknown>) => t('checkout.minimumOrderModal.withoutPickup.body', data), /* Add additional items worth {{additionalAmount}} or more to complete checkout. */
          continueShoppingCTA: () => t('checkout.minimumOrderModal.withoutPickup.continueShoppingCTA'), /* Continue Browsing */
        },
        withPickup: {
          title: (data: Record<'minAmount', unknown>) => t('checkout.minimumOrderModal.withPickup.title', data), /* We only deliver orders over {{minAmount}} */
          body: (data: Record<'additionalAmount', unknown>) => t('checkout.minimumOrderModal.withPickup.body', data), /* Choose pickup or add additional items worth at least {{additionalAmount}} to complete checkout. */
          gotItCTA: () => t('checkout.minimumOrderModal.withPickup.gotItCTA'), /* Got It */
        },
        delivery: {
          withPickup: {
            title: (data: Record<'minAmount', unknown>) => t('checkout.minimumOrderModal.delivery.withPickup.title', data), /* We only deliver orders over {{minAmount}} */
          },
          withoutPickup: {
            title: (data: Record<'minAmount', unknown>) => t('checkout.minimumOrderModal.delivery.withoutPickup.title', data), /* We only deliver orders over {{minAmount}} */
          },
        },
      },
      informative_modal: {
        return_to_cart: () => t('checkout.informative_modal.return_to_cart'), /* Return to Cart */
        cashier_internal_error: () => t('checkout.informative_modal.cashier_internal_error'), /* Please refresh the page and try again. */
        close_button: () => t('checkout.informative_modal.close_button'), /* Close */
        unsupported_shipping_destination: () => t('checkout.informative_modal.unsupported_shipping_destination'), /* Please enter a different address to complete your purchase. */
        cashier_internal_error_title: () => t('checkout.informative_modal.cashier_internal_error_title'), /* Looks like there's a connection problem */
        unsupported_shipping_destination_title: () => t('checkout.informative_modal.unsupported_shipping_destination_title'), /* Our items are not currently available in your region */
        wallet_payment_problem: () => t('checkout.informative_modal.wallet_payment_problem'), /* There was a problem with your payment */
      },
      orderSummary: {
        totals: {
          pickup: {
            label: () => t('checkout.orderSummary.totals.pickup.label'), /* Pickup */
          },
        },
        secureCheckout: {
          label: () => t('checkout.orderSummary.secureCheckout.label'), /* Secure Checkout */
        },
        amountDueNow: {
          label: () => t('checkout.orderSummary.amountDueNow.label'), /* Pay Now */
        },
        amountDueLater: {
          label: () => t('checkout.orderSummary.amountDueLater.label'), /* Pay Later */
        },
        itemAmount_ariaLabel: (data: Record<'itemNumber', unknown>) => t('checkout.orderSummary.itemAmount_ariaLabel', data), /* Number of items {{itemNumber}} */
        price: {
          ariaLabel: (data: Record<'price', unknown>) => t('checkout.orderSummary.price.ariaLabel', data), /* Price {{price}} */
        },
        regularPrice: {
          ariaLabel: (data: Record<'price', unknown>) => t('checkout.orderSummary.regularPrice.ariaLabel', data), /* Regular price {{price}} */
        },
        fullSection: {
          ariaLabel: () => t('checkout.orderSummary.fullSection.ariaLabel'), /* Items */
        },
        singleItem: {
          ariaLabel: () => t('checkout.orderSummary.singleItem.ariaLabel'), /* Item */
        },
        salePrice: {
          ariaLabel: (data: Record<'price', unknown>) => t('checkout.orderSummary.salePrice.ariaLabel', data), /* Sale price {{price}} */
        },
      },
      order_summary: {
        items: {
          quantity: () => t('checkout.order_summary.items.quantity'), /* Qty */
          more_details: () => t('checkout.order_summary.items.more_details'), /* More Details */
          lessDetails: () => t('checkout.order_summary.items.lessDetails'), /* Less Details */
          sku: () => t('checkout.order_summary.items.sku'), /* SKU */
          color: () => t('checkout.order_summary.items.color'), /* Color */
          size: () => t('checkout.order_summary.items.size'), /* Size */
        },
        item_out_of_stock_error: () => t('checkout.order_summary.item_out_of_stock_error'), /* This item is no longer available. Please remove it to complete your order. */
        totals: {
          subtotal: () => t('checkout.order_summary.totals.subtotal'), /* Subtotal */
          items: () => t('checkout.order_summary.totals.items'), /* Items */
          shipping: () => t('checkout.order_summary.totals.shipping'), /* Delivery */
          free: () => t('checkout.order_summary.totals.free'), /* Free */
          discount: {
            $value: (data: Record<'couponCode', unknown>) => t('checkout.order_summary.totals.discount', data), /* Promo Code: {{couponCode}} */
            free_shipping: () => t('checkout.order_summary.totals.discount.free_shipping'), /* Free delivery */
            free_delivery: () => t('checkout.order_summary.totals.discount.free_delivery'), /* Free delivery */
          },
          giftcard: () => t('checkout.order_summary.totals.giftcard'), /* Gift Card */
          tax: () => t('checkout.order_summary.totals.tax'), /* Tax */
          total: () => t('checkout.order_summary.totals.total'), /* Total */
          delivery: () => t('checkout.order_summary.totals.delivery'), /* Delivery */
        },
        $value: () => t('checkout.order_summary'), /* Order summary */
        removeFromCartError: {
          cta: () => t('checkout.order_summary.removeFromCartError.cta'), /* Remove */
        },
        removeItem: {
          cta: () => t('checkout.order_summary.removeItem.cta'), /* Remove Item */
        },
      },
      additionalFees: () => t('checkout.additionalFees'), /* Additional fees:  */
      additionalInfo: {
        custom_field: {
          apply: () => t('checkout.additionalInfo.custom_field.apply'), /* Apply */
        },
        missing_error: {
          required: () => t('checkout.additionalInfo.missing_error.required'), /* Required field */
        },
      },
      footer: {
        terms_and_condition_button_text: () => t('checkout.footer.terms_and_condition_button_text'), /* Terms & Conditions */
        privacy_policy_button_text: () => t('checkout.footer.privacy_policy_button_text'), /* Privacy Policy */
        digital_item_policy_button_text: () => t('checkout.footer.digital_item_policy_button_text'), /* Digital Item Policy */
        refund_cancellation_button_text: () => t('checkout.footer.refund_cancellation_button_text'), /* Return Policy */
        contact_us_button_text: () => t('checkout.footer.contact_us_button_text'), /* Contact Us */
        payment_policy_button_text: () => t('checkout.footer.payment_policy_button_text'), /* Payment Policy */
      },
      terms_and_condition_modal: {
        title: () => t('checkout.terms_and_condition_modal.title'), /* Terms and Conditions */
      },
      privacy_policy_modal: {
        title: () => t('checkout.privacy_policy_modal.title'), /* Privacy Policy */
      },
      refund_cancellation_modal: {
        title: () => t('checkout.refund_cancellation_modal.title'), /* Return Policy */
      },
      contact_us_modal: {
        title: () => t('checkout.contact_us_modal.title'), /* Contact Us */
      },
      continue_shopping: () => t('checkout.continue_shopping'), /* Continue Browsing */
      address_information: {
        zip_validation_errors: {
          invalid: () => t('checkout.address_information.zip_validation_errors.invalid'), /* Enter a valid zip / postal code. */
        },
        first_name_label: () => t('checkout.address_information.first_name_label'), /* First name */
        last_name_label: () => t('checkout.address_information.last_name_label'), /* Last name */
        company_label: () => t('checkout.address_information.company_label'), /* Company Name */
        phone_number_label: () => t('checkout.address_information.phone_number_label'), /* Phone */
        email_label: () => t('checkout.address_information.email_label'), /* Email for order confirmation */
        error_message: {
          missing_info: () => t('checkout.address_information.error_message.missing_info'), /* Some of your info is missing. */
          suffix: () => t('checkout.address_information.error_message.suffix'), /* <1>Edit</1> or <3>add</3> a new address. */
        },
      },
      edit_cart: () => t('checkout.edit_cart'), /* Edit Cart */
      deliveryMethod: {
        shipping: {
          label: () => t('checkout.deliveryMethod.shipping.label'), /* Shipping & delivery */
        },
        pickup: {
          label: () => t('checkout.deliveryMethod.pickup.label'), /* Pickup */
        },
        selectOption: {
          lable: () => t('checkout.deliveryMethod.selectOption.lable'), /* Select an option */
        },
        error: {
          unsupportedRegion: () => t('checkout.deliveryMethod.error.unsupportedRegion'), /* Sorry, our items are not currently available in your region. */
          missingZipCode: () => t('checkout.deliveryMethod.error.missingZipCode'), /* You're missing a zip / postal code. Go back to your delivery details to add it. */
        },
        shippingAndDelivery: {
          label: () => t('checkout.deliveryMethod.shippingAndDelivery.label'), /* Shipping & delivery */
        },
        date: {
          label: () => t('checkout.deliveryMethod.date.label'), /* Date* */
        },
        time: {
          label: () => t('checkout.deliveryMethod.time.label'), /* Time* */
        },
        shippingWithDeliveryTime: {
          dropdownOption: (data: Record<'time' | 'price', unknown>) => t('checkout.deliveryMethod.shippingWithDeliveryTime.dropdownOption', data), /* {{time}} ({{price}}) */
        },
        pickupFromPrice: {
          label: (data: Record<'price', unknown>) => t('checkout.deliveryMethod.pickupFromPrice.label', data), /* From {{price}} */
        },
        pickupWillBeReady: {
          title: () => t('checkout.deliveryMethod.pickupWillBeReady.title'), /* Your order will be ready */
        },
        checkbox: {
          true: () => t('checkout.deliveryMethod.checkbox.true'), /* Yes */
          false: () => t('checkout.deliveryMethod.checkbox.false'), /* No */
        },
        deliveryInstructions: {
          title: () => t('checkout.deliveryMethod.deliveryInstructions.title'), /* Delivery instructions */
        },
        deliveryProfiles: {
          availableForAllItems: {
            badge: () => t('checkout.deliveryMethod.deliveryProfiles.availableForAllItems.badge'), /* Available for all items */
          },
        },
      },
      delivery_method: {
        pickup_address: (data: Record<'addressLine' | 'city' | 'subdivision' | 'zipCode' | 'country', unknown>) => t('checkout.delivery_method.pickup_address', data), /* {{addressLine}}, {{city}}, {{subdivision}}, {{zipCode}}, {{country}} */
        free_label: () => t('checkout.delivery_method.free_label'), /* Free */
      },
      pickup_details_form: {
        store_pickup_address_header: () => t('checkout.pickup_details_form.store_pickup_address_header'), /* Pickup address */
        store_pickup_address_header2: () => t('checkout.pickup_details_form.store_pickup_address_header2'), /* Pickup availability */
        store_pickup_instructions_header: () => t('checkout.pickup_details_form.store_pickup_instructions_header'), /* Pickup instructions */
      },
      shipping_details: {
        delivery_address2: (data: Record<'addressLine1' | 'addressLine2' | 'city' | 'subdivision' | 'zipCode' | 'country', unknown>) => t('checkout.shipping_details.delivery_address2', data), /* {{addressLine1}}, {{addressLine2}}
        {{city}}, {{subdivision}} {{zipCode}}, {{country}} */
        delivery_address2_without_subdivision: (data: Record<'addressLine1' | 'addressLine2' | 'city' | 'zipCode' | 'country', unknown>) => t('checkout.shipping_details.delivery_address2_without_subdivision', data), /* {{addressLine1}}, {{addressLine2}}
        {{city}} {{zipCode}}, {{country}} */
      },
      summary: {
        shipping: {
          noAmount: () => t('checkout.summary.shipping.noAmount'), /* -- */
        },
      },
      terms_and_conditions: {
        checkbox: {
          privacy_policy: () => t('checkout.terms_and_conditions.checkbox.privacy_policy'), /* <0>Privacy Policy</0> */
          label: () => t('checkout.terms_and_conditions.checkbox.label'), /* I agree to the <0/> */
          digital_item_policy: () => t('checkout.terms_and_conditions.checkbox.digital_item_policy'), /* <0>Digital Item Policy</0> */
          add_item_to_list: () => t('checkout.terms_and_conditions.checkbox.add_item_to_list'), /* <0/>, <1/> */
          add_last_item: () => t('checkout.terms_and_conditions.checkbox.add_last_item'), /* <0/> and <1/> */
          return_policy: () => t('checkout.terms_and_conditions.checkbox.return_policy'), /* <0>Return Policy</0> */
          terms_and_conditions: () => t('checkout.terms_and_conditions.checkbox.terms_and_conditions'), /* <0>Terms & Conditions</0> */
          item_policy_backup_text: () => t('checkout.terms_and_conditions.checkbox.item_policy_backup_text'), /* Item Policy */
        },
      },
      subscription: {
        checkbox: () => t('checkout.subscription.checkbox'), /* I agree to receive marketing communications via email and/or SMS to any emails and phone numbers added above */
        new: {
          checkbox: () => t('checkout.subscription.new.checkbox'), /* I agree to receive news to the email address and/or phone numbers added */
        },
      },
      digital_item_policy_modal: {
        title: () => t('checkout.digital_item_policy_modal.title'), /* Digital Item Policy */
      },
      coupon: {
        label: () => t('checkout.coupon.label'), /* Enter a promo code */
        applyButton: () => t('checkout.coupon.applyButton'), /* Apply */
        removeButton: () => t('checkout.coupon.removeButton'), /* Remove */
        invalidGeneral: {
          error: () => t('checkout.coupon.invalidGeneral.error'), /* This promo code isn't valid. */
        },
        notActiveYet: {
          error: () => t('checkout.coupon.notActiveYet.error'), /* This promotion hasn't started yet. */
        },
        expired: {
          error: () => t('checkout.coupon.expired.error'), /* This promotion has ended. */
        },
        maxUsage: {
          error: () => t('checkout.coupon.maxUsage.error'), /* This promo code has exceeded the number of times it can be used. */
        },
        disabled: {
          error: () => t('checkout.coupon.disabled.error'), /* This promo code isn't valid. */
        },
        characterLimit: {
          error: () => t('checkout.coupon.characterLimit.error'), /* Your code can't have more than 20 characters. */
        },
        invalidProducts: {
          error: () => t('checkout.coupon.invalidProducts.error'), /* This promo code can only be used for specific products. */
        },
        invalidSubtotal: {
          error: (data: Record<'subtotal', unknown>) => t('checkout.coupon.invalidSubtotal.error', data), /* This promo code can only be used for orders above {{subtotal}}. */
        },
        productQuantity: {
          error: (data: Record<'quantity', unknown>) => t('checkout.coupon.productQuantity.error', data), /* This promotion only applies for purchases over {{quantity}} items. */
        },
        alreadyUsed: {
          error: () => t('checkout.coupon.alreadyUsed.error'), /* You already used this promo code. */
        },
        subscriptions: {
          error: () => t('checkout.coupon.subscriptions.error'), /* This promo code isn’t valid for subscriptions. */
        },
      },
      terms_and_condition: {
        checkbox_error: () => t('checkout.terms_and_condition.checkbox_error'), /* Please indicate that you’ve read and agree to the site's policies. */
      },
      myCartPaymentMethod: {
        offlinePlan: {
          lineItemWithId: (data: Record<'membership', unknown>) => t('checkout.myCartPaymentMethod.offlinePlan.lineItemWithId', data), /* Pay later with {{membership}} */
          lineItemWithoutId: () => t('checkout.myCartPaymentMethod.offlinePlan.lineItemWithoutId'), /* Pay later with a plan */
        },
        offlinePayment: {
          lineItem: () => t('checkout.myCartPaymentMethod.offlinePayment.lineItem'), /* Pay in person */
        },
        deposit: {
          lineItem: (data: Record<'price', unknown>) => t('checkout.myCartPaymentMethod.deposit.lineItem', data), /* Deposit: {{price}} */
        },
        onlinePlan: {
          lineItem: (data: Record<'membership', unknown>) => t('checkout.myCartPaymentMethod.onlinePlan.lineItem', data), /* Pay with {{membership}} */
        },
        payWithPlan: {
          lineItem: () => t('checkout.myCartPaymentMethod.payWithPlan.lineItem'), /* Pay with a plan */
        },
      },
      orderTotal: {
        fullSection: {
          ariaLabel: () => t('checkout.orderTotal.fullSection.ariaLabel'), /* Total due breakdown */
        },
        noShipping: {
          ariaLabel: () => t('checkout.orderTotal.noShipping.ariaLabel'), /* Not available */
        },
      },
      page_plan_autorenew: () => t('checkout.page_plan_autorenew'), /* Auto-renew until canceled */
      page_plan_months: () => t('checkout.page_plan_months'), /* month */
      page_plan_years: () => t('checkout.page_plan_years'), /* year */
      page_plan_month: () => t('checkout.page_plan_month'), /* month */
      page_plan_weeks: () => t('checkout.page_plan_weeks'), /* week */
      page_plan_week: () => t('checkout.page_plan_week'), /* week */
      page_plan_year: () => t('checkout.page_plan_year'), /* year */
      page_plan_duration_description_singular: (data: Record<'duration' | 'frequencyUnitSingular', unknown>) => t('checkout.page_plan_duration_description_singular', data), /* {{duration}} {{frequencyUnitSingular}} subscription */
      page_plan_duration_description_plural: (data: Record<'numberOfFrequencyUnits' | 'frequencyUnitSingular', unknown>) => t('checkout.page_plan_duration_description_plural', data), /* {{numberOfFrequencyUnits}} {{frequencyUnitSingular}} subscription */
      page_plan_day: () => t('checkout.page_plan_day'), /* day */
      page_plan_days: () => t('checkout.page_plan_days'), /* days */
      page_plan_frequency_description_days_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => t('checkout.page_plan_frequency_description_days_plural_label', data), /* every {{numberOfFrequencyUnits}} days */
      page_plan_frequency_description_weeks_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => t('checkout.page_plan_frequency_description_weeks_plural_label', data), /* every {{numberOfFrequencyUnits}} weeks */
      page_plan_frequency_description_months_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => t('checkout.page_plan_frequency_description_months_plural_label', data), /* every {{numberOfFrequencyUnits}} months */
      page_plan_frequency_description_years_plural_label: (data: Record<'numberOfFrequencyUnits', unknown>) => t('checkout.page_plan_frequency_description_years_plural_label', data), /* every {{numberOfFrequencyUnits}} years */
      page_plan_frequency_description_2_days_label: () => t('checkout.page_plan_frequency_description_2_days_label'), /* every 2 days */
      page_plan_frequency_description_2_weeks_label: () => t('checkout.page_plan_frequency_description_2_weeks_label'), /* every 2 weeks */
      page_plan_frequency_description_2_months_label: () => t('checkout.page_plan_frequency_description_2_months_label'), /* every 2 months */
      page_plan_frequency_description_2_years_label: () => t('checkout.page_plan_frequency_description_2_years_label'), /* every 2 years */
      page_plan_frequency_description_day_singular_label: () => t('checkout.page_plan_frequency_description_day_singular_label'), /* per day */
      page_plan_frequency_description_week_singular_label: () => t('checkout.page_plan_frequency_description_week_singular_label'), /* per week */
      page_plan_frequency_description_month_singular_label: () => t('checkout.page_plan_frequency_description_month_singular_label'), /* per month */
      page_plan_frequency_description_year_singular_label: () => t('checkout.page_plan_frequency_description_year_singular_label'), /* per year */
      promoCodeError: {
        invalidSubtotal: {
          title: (data: Record<'subtotal', unknown>) => t('checkout.promoCodeError.invalidSubtotal.title', data), /* This promo code can only be used for orders above {{subtotal}} */
          cta: () => t('checkout.promoCodeError.invalidSubtotal.cta'), /* Continue Browsing */
          subtitle: () => t('checkout.promoCodeError.invalidSubtotal.subtitle'), /* To complete your purchase, add additional items to your cart or remove the promo code. */
        },
        notActiveYet: {
          cta: () => t('checkout.promoCodeError.notActiveYet.cta'), /* Go Back to Checkout */
          title: () => t('checkout.promoCodeError.notActiveYet.title'), /* This promo code can’t be used yet */
          subtitle: () => t('checkout.promoCodeError.notActiveYet.subtitle'), /* This promotion hasn’t started yet. If you want to complete your purchase now, go back and remove the promo code. */
        },
        subscriptionsNotValid: {
          subtitle: () => t('checkout.promoCodeError.subscriptionsNotValid.subtitle'), /* To complete your purchase, go back and remove the promo code. */
          cta: () => t('checkout.promoCodeError.subscriptionsNotValid.cta'), /* Go Back to Checkout */
          title: () => t('checkout.promoCodeError.subscriptionsNotValid.title'), /* This promo code can’t be used for subscriptions */
        },
        expired: {
          subtitle: () => t('checkout.promoCodeError.expired.subtitle'), /* This promotion has ended. To complete your purchase, go back and remove the promo code. */
          cta: () => t('checkout.promoCodeError.expired.cta'), /* Go Back to Checkout */
          title: () => t('checkout.promoCodeError.expired.title'), /* This promo code can’t be used anymore */
        },
        invalidProductQuantity: {
          cta: () => t('checkout.promoCodeError.invalidProductQuantity.cta'), /* Continue Browsing */
          subtitle: (data: Record<'quantity', unknown>) => t('checkout.promoCodeError.invalidProductQuantity.subtitle', data), /* This promotion only applies for purchases over {{quantity}} items. To complete your purchase, add more items to your cart or remove the promo code. */
          title: () => t('checkout.promoCodeError.invalidProductQuantity.title'), /* This promo code can’t be applied */
        },
        invalidProducts: {
          title: () => t('checkout.promoCodeError.invalidProducts.title'), /* This promo code can only be used for specific items */
          subtitle: () => t('checkout.promoCodeError.invalidProducts.subtitle'), /* To complete your purchase, add items that are part of this promotion or remove the promo code. */
          cta: () => t('checkout.promoCodeError.invalidProducts.cta'), /* Continue Browsing */
        },
        doesntExist: {
          title: () => t('checkout.promoCodeError.doesntExist.title'), /* This promo code isn't valid */
          subtitle: () => t('checkout.promoCodeError.doesntExist.subtitle'), /* To complete your purchase, check your code again or remove it from your cart. */
          cta: () => t('checkout.promoCodeError.doesntExist.cta'), /* Go Back to Checkout */
        },
        exceededUsage: {
          title: () => t('checkout.promoCodeError.exceededUsage.title'), /* This promo code can’t be used anymore */
          cta: () => t('checkout.promoCodeError.exceededUsage.cta'), /* Go Back to Checkout */
          subtitle: () => t('checkout.promoCodeError.exceededUsage.subtitle'), /* This promo code has exceeded the number of times it can be used. To complete your purchase, go back and remove it. */
        },
        limitedUsage: {
          title: () => t('checkout.promoCodeError.limitedUsage.title'), /* You’ve already used this promo code */
          cta: () => t('checkout.promoCodeError.limitedUsage.cta'), /* Go Back to Checkout */
          subtitle: () => t('checkout.promoCodeError.limitedUsage.subtitle'), /* This coupon can only be used one time per customer. To complete your purchase, remove the promo code. */
        },
        disabled: {
          subtitle: () => t('checkout.promoCodeError.disabled.subtitle'), /* To complete your purchase, go back and remove the promo code. */
          title: () => t('checkout.promoCodeError.disabled.title'), /* This promo code isn't valid */
          cta: () => t('checkout.promoCodeError.disabled.cta'), /* Go Back to Checkout */
        },
        subscriptionWithZeroTotal: {
          title: () => t('checkout.promoCodeError.subscriptionWithZeroTotal.title'), /* This promo code can't be used for subscriptions */
          subtitle: () => t('checkout.promoCodeError.subscriptionWithZeroTotal.subtitle'), /* To complete your purchase, go back and remove the promo code. */
          cta: () => t('checkout.promoCodeError.subscriptionWithZeroTotal.cta'), /* Go Back to Checkout */
        },
      },
      bookingsError: {
        insufficientPlanSessions: {
          title: () => t('checkout.bookingsError.insufficientPlanSessions.title'), /* Your plan doesn’t have enough sessions left */
          subtitle: () => t('checkout.bookingsError.insufficientPlanSessions.subtitle'), /* To complete your order, check how many sessions you have left and remove the extra sessions. Once you’ve finished the remaining sessions for this plan, you can purchase a new plan or use a renewed plan. */
          cta: () => t('checkout.bookingsError.insufficientPlanSessions.cta'), /* Go Back to Cart */
        },
      },
      membersInfoBox: {
        login: () => t('checkout.membersInfoBox.login'), /* Have an account? <1>Log in</1> */
        loggedInMessage: (data: Record<'email', unknown>) => t('checkout.membersInfoBox.loggedInMessage', data), /* Logged in as {{email}} */
        logout: () => t('checkout.membersInfoBox.logout'), /* Log out */
      },
      customer_details: () => t('checkout.customer_details'), /* Customer details */
      shipping_details_title: () => t('checkout.shipping_details_title'), /* Delivery details */
      payment: () => t('checkout.payment'), /* Payment */
      continue_button_label: () => t('checkout.continue_button_label'), /* Continue */
      edit: () => t('checkout.edit'), /* Edit */
      change: () => t('checkout.change'), /* Change */
      billing_information: {
        title: () => t('checkout.billing_information.title'), /* Billing address */
        billing_same_as_shipping_label: () => t('checkout.billing_information.billing_same_as_shipping_label'), /* Same as delivery address */
        billing_same_as_delivery_label: () => t('checkout.billing_information.billing_same_as_delivery_label'), /* Same as delivery address */
      },
      customerName: {
        label: (data: Record<'firstName' | 'lastName', unknown>) => t('checkout.customerName.label', data), /* {{firstName}} {{lastName}} */
      },
      extended_fields_view: {
        checkbox: {
          true: () => t('checkout.extended_fields_view.checkbox.true'), /* Yes */
          false: () => t('checkout.extended_fields_view.checkbox.false'), /* No */
        },
      },
      collapsible_section: {
        more_details: () => t('checkout.collapsible_section.more_details'), /* More Details */
        less_details: () => t('checkout.collapsible_section.less_details'), /* Less Details */
      },
      logOutModal: {
        title: () => t('checkout.logOutModal.title'), /* Log out of site? */
        body: (data: Record<'memberEmail', unknown>) => t('checkout.logOutModal.body', data), /* Your info and items will be available when you log back in to {{memberEmail}}. */
        mainButton: () => t('checkout.logOutModal.mainButton'), /* Log Out */
        secondaryButton: () => t('checkout.logOutModal.secondaryButton'), /* Return to Checkout */
      },
      member: {
        addressDropdown: {
          addNewAddress: {
            cta: () => t('checkout.member.addressDropdown.addNewAddress.cta'), /* Add a New Address */
          },
        },
      },
      cancel: {
        cta: () => t('checkout.cancel.cta'), /* Cancel */
      },
      saveAndContinue: {
        cta: () => t('checkout.saveAndContinue.cta'), /* Save & Continue */
      },
      chooseNewAddress: {
        cta: () => t('checkout.chooseNewAddress.cta'), /* Use a Different Address */
      },
      saveAddress: {
        checkbox: () => t('checkout.saveAddress.checkbox'), /* Save this address */
      },
      defaultAddress: {
        checkbox: () => t('checkout.defaultAddress.checkbox'), /* Make this my default address */
      },
      errorModal: {
        validationsSPI: {
          title: () => t('checkout.errorModal.validationsSPI.title'), /* This order can’t be placed */
          subtitle: () => t('checkout.errorModal.validationsSPI.subtitle'), /* Please review your order details and try again. */
          cta: () => t('checkout.errorModal.validationsSPI.cta'), /* Close */
        },
        totalPriceChanged: {
          title: () => t('checkout.errorModal.totalPriceChanged.title'), /* Your order total was updated */
          subtitle: () => t('checkout.errorModal.totalPriceChanged.subtitle'), /* This could be because of a pricing change, updated delivery rates or a promotion that ended. Refresh the page to see your updated total or contact us for more details. */
          cta: () => t('checkout.errorModal.totalPriceChanged.cta'), /* Got It */
        },
      },
      delivery_details_title: () => t('checkout.delivery_details_title'), /* Delivery details */
      deliveryMethodOptions: {
        enterAddress: {
          sectionNotification: () => t('checkout.deliveryMethodOptions.enterAddress.sectionNotification'), /* To check for more delivery options, <1>enter an address</1>. */
        },
      },
      placeOrderPay: {
        button: () => t('checkout.placeOrderPay.button'), /* Place Order & Pay */
      },
      customer_delivery_details_title: () => t('checkout.customer_delivery_details_title'), /* Customer & delivery details */
      mobile: {
        showOrderSummary: {
          label: () => t('checkout.mobile.showOrderSummary.label'), /* Show summary */
        },
        hideOrderSummary: {
          label: () => t('checkout.mobile.hideOrderSummary.label'), /* Hide summary */
        },
        showItems: {
          label: (data: Record<'number', unknown>) => t('checkout.mobile.showItems.label', data), /* Show items ({{number}}) */
        },
        hideItems: {
          label: (data: Record<'number', unknown>) => t('checkout.mobile.hideItems.label', data), /* Hide items ({{number}}) */
        },
        alertIcon: {
          ariaLabel: () => t('checkout.mobile.alertIcon.ariaLabel'), /* There is an issue with your order. Review the order summary */
        },
      },
      billingAddress: {
        save: {
          cta: () => t('checkout.billingAddress.save.cta'), /* Save Billing Address */
        },
      },
      donations: {
        address_information: {
          donor_email_label: () => t('checkout.donations.address_information.donor_email_label'), /* Email for donation confirmation */
        },
        review_donation: {
          description: () => t('checkout.donations.review_donation.description'), /* Check the donation details above, and complete your donation when you're ready. */
          title: () => t('checkout.donations.review_donation.title'), /* Review your donation */
        },
        donateNow: {
          button: () => t('checkout.donations.donateNow.button'), /* Donate Now */
        },
        donor_details: () => t('checkout.donations.donor_details'), /* Donor details */
        donation_summary: () => t('checkout.donations.donation_summary'), /* Your donation */
      },
      payment_policy_modal: {
        title: () => t('checkout.payment_policy_modal.title'), /* Payment Policy */
      },
      policies: {
        checkbox: {
          payment_policy: () => t('checkout.policies.checkbox.payment_policy'), /* <0>Payment Policy</0> which may include cancellation fees */
          custom_policy: (data: Record<'customPolicyTitle', unknown>) => t('checkout.policies.checkbox.custom_policy', data), /* <0>{{customPolicyTitle}}</0> */
          item_policy: (data: Record<'itemPolicyTitle', unknown>) => t('checkout.policies.checkbox.item_policy', data), /* <0>{{itemPolicyTitle}}</0> */
        },
      },
      payment_policy: {
        checkbox_error: () => t('checkout.payment_policy.checkbox_error'), /* Please indicate that you’ve read and agree to the payment policies. */
        checkbox: {
          label: () => t('checkout.payment_policy.checkbox.label'), /* I agree to the <0/> */
        },
      },
      payLinks: {
        thankYouPage: {
          orderNumber: (data: Record<'incrementalID', unknown>) => t('checkout.payLinks.thankYouPage.orderNumber', data), /* Order number: {{incrementalID}} */
          amountPaid: (data: Record<'amountPaid', unknown>) => t('checkout.payLinks.thankYouPage.amountPaid', data), /* Amount paid: {{amountPaid}} */
          subtitle: () => t('checkout.payLinks.thankYouPage.subtitle'), /* You’ll receive a confirmation email when the payment is processed. */
          title: () => t('checkout.payLinks.thankYouPage.title'), /* Thank you for your payment */
        },
        generalError: {
          title: () => t('checkout.payLinks.generalError.title'), /* Couldn’t open the link */
          subtitle: () => t('checkout.payLinks.generalError.subtitle'), /* We’re experiencing a technical issue on our end. Refresh the page and try again. */
        },
        linkExpired: {
          title: () => t('checkout.payLinks.linkExpired.title'), /* This pay link has expired */
          subtitle: () => t('checkout.payLinks.linkExpired.subtitle'), /* If there is a mistake or for further assistance, please contact us. */
        },
        linkNotFound: {
          subtitle: () => t('checkout.payLinks.linkNotFound.subtitle'), /* Refresh your page to try again. If there is still an issue, please contact us. */
          title: () => t('checkout.payLinks.linkNotFound.title'), /* Pay link not found */
        },
        mainCta: () => t('checkout.payLinks.mainCta'), /* Pay Now */
        paylinkValid: {
          disclaimer: (data: Record<'expirationDate', unknown>) => t('checkout.payLinks.paylinkValid.disclaimer', data), /* This pay link is valid until {{expirationDate}}. */
        },
        couldntProcessPayment: {
          title: () => t('checkout.payLinks.couldntProcessPayment.title'), /* We couldn’t process your payment */
          subtitle: () => t('checkout.payLinks.couldntProcessPayment.subtitle'), /* There was an issue with the payment provider. Try again or select a different payment method. */
          cta: () => t('checkout.payLinks.couldntProcessPayment.cta'), /* Go Back to Checkout */
        },
      },
      pricingPlans: {
        freeTrial: {
          label: (data: Record<'number' | 'frequency', unknown>) => t('checkout.pricingPlans.freeTrial.label', data), /* Free trial: {{number}} {{frequency}} */
        },
        totalToday: {
          label: () => t('checkout.pricingPlans.totalToday.label'), /* Total today */
        },
        duration: {
          label: (data: Record<'number' | 'frequency', unknown>) => t('checkout.pricingPlans.duration.label', data), /* Duration: {{number}} {{frequency}} */
        },
        frequency: {
          years: {
            plural: () => t('checkout.pricingPlans.frequency.years.plural'), /* years */
          },
          days: {
            plural: () => t('checkout.pricingPlans.frequency.days.plural'), /* days */
          },
          month: {
            singular: () => t('checkout.pricingPlans.frequency.month.singular'), /* month */
          },
          weeks: {
            plural: () => t('checkout.pricingPlans.frequency.weeks.plural'), /* weeks */
          },
          week: {
            singular: () => t('checkout.pricingPlans.frequency.week.singular'), /* week */
          },
          months: {
            plural: () => t('checkout.pricingPlans.frequency.months.plural'), /* months */
          },
          year: {
            singular: () => t('checkout.pricingPlans.frequency.year.singular'), /* year */
          },
          day: {
            singular: () => t('checkout.pricingPlans.frequency.day.singular'), /* day */
          },
        },
        afterFreeTrial: {
          label: () => t('checkout.pricingPlans.afterFreeTrial.label'), /* After free trial */
        },
        startDate: {
          label: (data: Record<'date', unknown>) => t('checkout.pricingPlans.startDate.label', data), /* Start date: {{date}} */
        },
      },
      emailField: {
        input: {
          error: {
            message: () => t('checkout.emailField.input.error.message'), /* Enter an email address like example@mysite.com. */
          },
        },
      },
      membersSignIn: {
        title: () => t('checkout.membersSignIn.title'), /* Log in */
        subtitle: () => t('checkout.membersSignIn.subtitle'), /* To purchase these items, log in to your account or sign up. */
        logIn: {
          cta: () => t('checkout.membersSignIn.logIn.cta'), /* Log In */
        },
        signUp: {
          cta: () => t('checkout.membersSignIn.signUp.cta'), /* Sign Up */
        },
      },
    },
    cart: {
      validation: {
        error: {
          EMPTY_CART: {
            title: () => t('cart.validation.error.EMPTY_CART.title'), /* You can't place your order just yet */
            content: () => t('cart.validation.error.EMPTY_CART.content'), /* It looks like your cart is empty. */
            button: () => t('cart.validation.error.EMPTY_CART.button'), /* Back to Site */
          },
          FULL_OUT_OF_STOCK: {
            title: () => t('cart.validation.error.FULL_OUT_OF_STOCK.title'), /* One or more of your items are sold out or no longer available */
            content: () => t('cart.validation.error.FULL_OUT_OF_STOCK.content'), /* Please go back and choose something else. */
            button: () => t('cart.validation.error.FULL_OUT_OF_STOCK.button'), /* Back to Site */
          },
          PARTIAL_OUT_OF_STOCK: {
            title: () => t('cart.validation.error.PARTIAL_OUT_OF_STOCK.title'), /* You can't place your order just yet */
            content: () => t('cart.validation.error.PARTIAL_OUT_OF_STOCK.content'), /* One or more items in your cart have sold out. To continue please remove them from your cart. */
            button: () => t('cart.validation.error.PARTIAL_OUT_OF_STOCK.button'), /* Got It */
          },
          PAYMENT_IN_PROCESS: {
            title: () => t('cart.validation.error.PAYMENT_IN_PROCESS.title'), /* Your last payment is still being processed */
            content: () => t('cart.validation.error.PAYMENT_IN_PROCESS.content'), /* You can't complete another order until your payment is processed. Please try again later. */
            button: () => t('cart.validation.error.PAYMENT_IN_PROCESS.button'), /* Got It */
          },
        },
      },
    },
    app: {
      widget: {
        welcome: () => t('app.widget.welcome'), /* Welcome */
      },
      settings: {
        label: () => t('app.settings.label'), /* Configure Widget */
        tabs: {
          main: () => t('app.settings.tabs.main'), /* Main */
          design: () => t('app.settings.tabs.design'), /* Design */
        },
        defaults: {
          greetingsPretext: () => t('app.settings.defaults.greetingsPretext'), /* to */
        },
      },
    },
    chooseAddressModal: {
      billing_address_title: () => t('chooseAddressModal.billing_address_title'), /* Choose a billing address */
      shipping_address_title: () => t('chooseAddressModal.shipping_address_title'), /* Choose an address */
    },
    ecom: {
      email: {
        label: () => t('ecom.email.label'), /* Email */
      },
    },
  };
}

export type ILocaleKeys = ReturnType<typeof LocaleKeys>;

const LocaleKeysContext = React.createContext({} as ILocaleKeys);
export const LocaleKeysProvider: React.FC<{ translateFn?: (...args: unknown[]) => string; localeKeys?: ILocaleKeys; children?: React.ReactNode }> = ({ translateFn, localeKeys, children }) => {
    if (!translateFn && !localeKeys) { throw new Error('Either translateFn or localeKeys must be provided') }
    const value = (typeof translateFn === 'function' ? LocaleKeys(translateFn) : localeKeys) as ILocaleKeys
    return <LocaleKeysContext.Provider value={value}>{children}</LocaleKeysContext.Provider>;
  };
export const useLocaleKeys = () => React.useContext(LocaleKeysContext);
