import {FORMS_TEMPLATE_IDS} from '../../constants';
import {FormViewer} from '@wix/form-viewer/widget';
import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useICUI18n} from '../../Hooks/useICUI18n';
import {getBillingFormOverrides, getCountryCode} from './BillingForm.utils';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {FormOverrides} from '@wix/form-viewer';
import {FormProps} from '../form.utils';
import {SPECS} from '../../../../common/constants';

export type BillingFormProps = FormProps;

const BillingFormInternal = ({formRef, formValues, setFormValues, formErrors, setFormErrors}: BillingFormProps) => {
  const {
    checkoutSettingsStore: {checkoutSettings},
    paymentStore: {cashierMandatoryFields},
    formsStore: {shippingDetailsFieldsProps},
  } = useControllerProps();

  const i18n = useICUI18n();
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const country = getCountryCode(formValues);

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      externalData={experiments?.enabled(SPECS.ShowVatIdOnBillingForIsrael) ? {country} : undefined}
      overrides={
        getBillingFormOverrides({
          cashierMandatoryFields,
          checkoutSettings,
          shippingDetailsFieldsProps: shippingDetailsFieldsProps ?? /* istanbul ignore next */ {},
          excludeGoogleAutoComplete: experiments.enabled(SPECS.CheckoutExcludeGoogleAutoComplete),
          localeKeys,
          country,
          experiments,
        }) as FormOverrides
      }
      formId={FORMS_TEMPLATE_IDS.BILLING}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
    />
  );
};

export const BillingForm = React.memo(BillingFormInternal);
